import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';


const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: 'app-layout',
        component: AppLayoutComponent,
        children: [
            { path: 'dash', loadChildren: () => import('./main/components/supplier-dashboard/supplier-dashboard.module').then(m => m.SupplierDashboardModule), canActivate: [MsalGuard] },
            { path: 'supplier-analytics', data: { breadcrumb: 'Accueil' }, loadChildren: () => import('./main/components/supplier-dashboard/supplier-dashboard.module').then(m => m.SupplierDashboardModule) },
            { path: 'supplier-operations', data: { breadcrumb: "Opération d'achat" }, loadChildren: () => import('./main/components/supplier-operations/supplier-operations.module').then(m => m.SupplierOperationsModule), canActivate: [MsalGuard] },
            { path: 'supplier-tracking', data: { breadcrumb: "Consultation des demandes d'achat" }, loadChildren: () => import('./main/components/supplier-tracking/supplier-tracking.module').then(m => m.SupplierTrackingModule), canActivate: [MsalGuard] },
            { path: 'supplier-invoice', data: { breadcrumb: "Consultation des factures" }, loadChildren: () => import('./main/components/supplier-invoice/supplier-invoice.module').then(m => m.SupplierInvoiceModule), canActivate: [MsalGuard] },
            { path: 'supplier-exceptionnal-authorization', data: { breadcrumb: "Dérogation" }, loadChildren: () => import('./main/components/supplier-exceptionnal-authorization/supplier-exceptionnal-authorization.module').then(m => m.SupplierExceptionnalAuthorizationModule), canActivate: [MsalGuard] }
        ]
    },
    { path: '', loadChildren: () => import('./main/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./main/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: 'access-denied', loadChildren: () => import('./main/components/access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'offline-exception', loadChildren: () => import('./main/components/offline-exception/offline-exception.module').then(m => m.OfflineExceptionModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
