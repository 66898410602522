<div
    class="layout-breadcrumb flex align-items-center relative prevent-select h-3rem"
>
    <nav>
        <ol class="relative px-3 z-2">
            <ng-template
                ngFor
                let-item
                let-last="last"
                let-first="first"
                [ngForOf]="breadcrumbs$ | async"
            >
                <li *ngIf="first">
                    <img
                        *ngIf="userAD"
                        [src]="userAD?.logoPath"
                        [style.width]="width(userAD.companyShortName)"
                    />
                </li>
                <div *ngIf="item?.fragment">
                    <li class="flex align-items-center gap-2">
                        <img
                            *ngIf="item?.fragment && first"
                            [src]="item?.fragment"
                            width="30"
                        />
                        <span
                            *ngIf="!first"
                            [ngClass]="{ 'text-primary font-medium ': last }"
                            class="text-700 font-medium text-lg hover:surface-hover cursor-pointer p-2 transition-duration-150 border-round"
                            style="font-style: oblique"
                            >{{ item.label }}</span
                        >
                        <span
                            *ngIf="first"
                            style="font-size: 1.2rem !important"
                            class="tc cursor-pointer p-2 transition-duration-150 border-round"
                            >{{ item.label }}</span
                        >
                    </li>
                </div>

                <div *ngIf="!item.fragment">
                    <li
                        [ngClass]="{
                            'text-primary font-medium ': last
                        }"
                        class="text-700 font-medium text-lg hover:surface-hover cursor-pointer p-2 transition-duration-150 border-round"
                        style="font-style: oblique"
                    >
                        {{ item.label }}
                    </li>
                </div>

                <li *ngIf="!last" class="layout-breadcrumb-chevron pt-1">
                    <i
                        class="text-primary pi pi-angle-right"
                        style="font-size: large"
                    ></i>
                </li>
            </ng-template>
        </ol>
    </nav>
</div>
